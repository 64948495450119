import {
	ConsumerLoanProduct,
	type EstoProductType,
	NonLoanProduct,
} from '@/shared/types';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { DEALS_IDS } from '@widgets/deals/list';
import * as z from 'zod';

const dashboardSearchSchema = z.object({
	hash: z.string().optional(),
	showNewsletterPopup: z.boolean().optional().catch(undefined),
	selectedDeal: z.enum(DEALS_IDS).optional().catch(undefined),
	agreementReferenceKey: z.string().optional().catch(undefined),
	selectedLoanOffer: z
		.custom<EstoProductType>(
			(value) =>
				!value ||
				value in ConsumerLoanProduct ||
				value === NonLoanProduct.CREDIT_LINE,
		)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/_main/dashboard')({
	validateSearch: dashboardSearchSchema,
	beforeLoad: ({ search: { hash } }) => {
		if (hash) {
			throw redirect({
				replace: true,
				to: '/credit-account-withdrawal',
				search: {
					hash,
				},
			});
		}
	},
});
