import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const agreementsSearchSchema = z.object({
	agreementReferenceKey: z.string().optional().catch(undefined),
	pastAgreementReferenceKey: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/_protected/_main/agreements')({
	validateSearch: agreementsSearchSchema,
});
